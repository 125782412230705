import { Text } from '../text/Text'
import styles from './AgmAlert.module.scss'

export const AgmAlert = () => (
  <div className={styles.root}>
    <Text>
      <h2>Notice of Annual General Meeting</h2>
      <p>
        Everton District Cricket Club will hold its 2024 Annual General Meeting (AGM) at 3pm, Saturday the 7th of September 2024. All
        members are encouraged to attend.
      </p>
      <p>
        Presented with this notice are the{' '}
        <a
          href="https://edcccoldstorage.blob.core.windows.net/agm-documents-2024/EDCC 2024 Annual General Meeting (AGM) Agenda.pdf"
          target="_blank"
          download
        >
          Agenda
        </a>
        {' for the upcoming AGM, '}
        <a
          href="https://edcccoldstorage.blob.core.windows.net/agm-documents-2024/EDCC 2023 Annual General Meeting (AGM) Minutes.pdf"
          target="_blank"
          download
        >
          Minutes
        </a>{' '}
        of the previous meeting,{' '}
        <a
          href="https://edcccoldstorage.blob.core.windows.net/agm-documents-2024/EDCC 2024 Annual General Meeting Notice of Motions.pdf"
          target="_blank"
          download
        >
          Notice of Motions
        </a>{' '}
        and{' '}
        <a
          href="https://edcccoldstorage.blob.core.windows.net/agm-documents-2024/EDCC 2024 Annual General Meeting Office Bearer Nomination Form.pdf"
          target="_blank"
          download
        >
          Office Bearer/Role Nomination Form
        </a>
        .
      </p>
      <p>The AGM will be preceded by a Senior pre-season warm-up and followed by drinks at the clubhouse.</p>
      <p>
        Should you desire any further information please contact us at <a href="mailto:EvertonDCC@outlook.com">EvertonDCC@outlook.com</a>
      </p>
    </Text>
  </div>
)
