import { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import ReactModal from 'react-modal'
import { Contact } from '../components/contact/Contact'
import { ContentWrapper } from '../components/contentWrapper/ContentWrapper'
import { Facilities } from '../components/facilities/Facilities'
import { Gallery } from '../components/gallery/Gallery'
import { Play } from '../components/play/Play'
import { ScrollToTopButton } from '../components/scrollToTopButton/ScrollToTopButton'
import { Welcome } from '../components/welcome/Welcome'
import styles from './Views.module.scss'
import noScroll from 'no-scroll'
import { Registration } from '../components/registration/Registration'
import { AgmAlert } from '../components/agmAlert/AgmAlert'

export const LandingView = () => {
  const [modalContent, setModalContent] = useState<JSX.Element | undefined>(undefined)

  return (
    <>
      <ContentWrapper>
        <AgmAlert />
        <Registration />
        <Welcome />
        <Play />
        <Facilities setModalContent={setModalContent} />
        <Gallery preview />
        <Contact />
      </ContentWrapper>
      <ScrollToTopButton mode="light" />
      <ReactModal
        ariaHideApp={false}
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={!!modalContent}
        onRequestClose={() => setModalContent(undefined)}
        onAfterOpen={() => noScroll.on()}
        onAfterClose={() => noScroll.off()}
      >
        <ContentWrapper>
          <button onClick={() => setModalContent(undefined)}>
            <GrClose />
          </button>
          {modalContent}
        </ContentWrapper>
      </ReactModal>
    </>
  )
}
